
// NavbarComponent.js
import React from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
  faGoogle,
  faLinkedin,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
<div className=''>  
<div className='foot'>
<div className="container-fluid flex-grow-1 ">
      <div className="row ">
        <div className="col-lg-12  text-white text-center">
          <h1 className="display-3 mt-5 mb-5 lead1">Do You Know</h1>
          <p className="lead mb-5">Mental health is vital for a fulfilling life! At Shepherd Mental 
            Health Foundation, we raise awareness, offer support, and advocate for mental wellness. 
            Join us in breaking the stigma and prioritizing mental health for all</p>
       
                        
         
      </div>
    </div>
    <div className="formthree ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-md-8 col-md-offset-2">
                        <div>
                            <div className="row">
                                
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="sr-only placeholder footercontact">Email</label>
                                        <input type="email" className="form-control" required="" id="emailSix" placeholder="Email Address" />
                                    </div>
                                </div>
                         
                        
                            <button type="submit" className="btn btnprim">Subscribe</button>
                        </div>
                    </div>
                 
                </div>
           
            </div>
  
        </div>

  </div>
  </div> 



  <footer className="bg-white">
        <div className="container py-4 text-center">
          {/* === Social Media Icons === */}
          <div className="row justify-content-center">
            <div className="col-12">
              <ul className="list-inline d-flex justify-content-center mt-5 social-icons">
                <li className="list-inline-item">
                  <Link to="/" title="Facebook" className="social-link facebook">
                    <FontAwesomeIcon icon={faFacebookF} size="lg" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/" title="Twitter" className="social-link twitter">
                    <FontAwesomeIcon icon={faTwitter} size="lg" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/" title="Google" className="social-link google">
                    <FontAwesomeIcon icon={faGoogle} size="lg" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/" title="Instagram" className="social-link instagram">
                    <FontAwesomeIcon icon={faInstagram} size="lg" />
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="/" title="LinkedIn" className="social-link linkedin">
                    <FontAwesomeIcon icon={faLinkedin} size="lg" />
                  </Link>
                </li>
              
              </ul>
            </div>
          </div>
        </div>

        {/* === Copyright Section === */}
        <div className="bg-light py-3">
          <div className="container text-center">
            <p className="text-muted mb-0 py-2">
              &copy; {new Date().getFullYear()} Shepherd Mental Health Foundation. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
</div>
</div>
  );
};

export default Footer;

