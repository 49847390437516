import React from 'react';
import NavBar from '../../component/Navbar';
import Heroe from '../../component/Heroe';
import './About.css'
import Mental1 from '../../IMG/heroe2.jpeg';
import backgroundImage from '../../IMG/mental2.png';
import Footer from '../../component/footer';

// Define a functional component using const
const About = () => {
  return (
    <div>
     <NavBar />
      <Heroe />
     <div className='m-5'>
        <div className='row'>
            <div className='col-lg-8 col-md-8 col-sm-12'>
                <div className='Abt1'>
                Welcome to the Shepherd Mental Health Foundation
                </div>
                <div className='Abt2'>
                At the Shepherd Mental Health Foundation, we hold a profound belief: 
                that mental health is not merely one component of overall health—it is 
                very foundation. Our approach reflects an unwavering dedication to 
                advancing mental health as a fundamental cornerstone of human well-being. 
                This belief drives everything we do, shaping our vision, programs, and 
                interactions with the individuals and communities we serve.
                </div>
                <div className='Abt2'>
                We envision a world where mental health is understood, respected, 
                and prioritized as a vital part of overall well-being. Our goal is to create 
                a society where everyone has access to the resources and support they need to live
               mentally healthy lives, free from stigma and discrimination.
                </div>
                <div className='Abt2'>
                Our mission is to raise awareness about the importance of mental health and to provide 
                individuals with the tools and knowledge they need to achieve and maintain mental wellness. 
                We work tirelessly to break down the barriers that prevent people from seeking help and to
                 create a supportive environment where everyone feels empowered to take charge of their 
                 mental health.
                </div>

                <div className='Abt2'>
                We invite you to join us in our mission to make mental health a priority for all. 
                Whether you are seeking support, looking to volunteer, or wanting to learn more about mental 
                health, the Shepherd Mental Health Foundation is here for you. Together, we can create a 
                world where mental health is valued and protected, and where everyone has the opportunity 
                to live their best life.
                </div>

            </div>

            <div className='col-lg-4'>
                <img src= {Mental1} alt='' className='Abtimg1' />
            </div>
        </div>
     </div>
     <div
      className="quote-section"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="quote-container">
        <blockquote className="quote-text">
        <div className='Abt1'>
               Our vision
                </div>
                <div className='Abt2'>
                We envision a world where mental health is understood, respected, 
                and prioritized as a vital part of overall well-being. Our goal is to create 
                a society where everyone has access to the resources and support they need to live
                 mentally healthy lives, free from stigma and discrimination.
                </div>
        </blockquote>
        
      </div>
    </div>
<div className='mt-4'>
    <Footer />
    </div>
    </div>
  );
};

// Export the component
export default About;
