// NavbarComponent.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../IMG/logo.jpg';
import './navbar.css';

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container container-fluid">
        <Link to="/" className="navbar-brand justify-content-end">
          <img src={logo} alt="Logo" className="logo" />
          <div className="logo_para">THE SHEPHERD MENTAL HEALTH FOUNDATION</div>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/story" className="nav-link">
                Blog
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/AboutUs" className="nav-link">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
 