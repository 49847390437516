import { BrowserRouter, Routes, Route, Redirect } from 'react-router-dom';
import Home from './Pages/home';
import FullStory from './Pages/Blog/fullstory';
import About from './Pages/AboutUs';
import MainBlog from './Pages/Blog';



const Homepage = () => {
  return (
   <BrowserRouter>
   <Routes>
<Route path="/" element= {<Home /> } />
<Route path="/story/:id" element={<FullStory />} />
<Route path="/AboutUs" element={<About />} />
<Route path='/story' element={<MainBlog />} />
</Routes>
   </BrowserRouter>
  );
};


function App() {
  return (
    <div className="App">
      <Homepage />
    </div>
  );
}

export default App;
