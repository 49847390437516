import React from 'react';
import blogPosts from '../../component/StoryApi/ArticleApi';
import './fullstory.css';
import { Link, useNavigate } from 'react-router-dom';
import BlogNavbar from '../../component/Navbar/blognav';

// Define a functional component using const

const featuredPost = blogPosts[0];
const backgroundImageUrl = featuredPost.imgSrc;
const divStyle = {
  backgroundImage: `url(${backgroundImageUrl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

const MainBlog = () => {
  const navigate = useNavigate();

  const handlefullblog = (id) => {
    navigate(`/story/${id}`);
  };

  return (
    <div>
      <div className="container">
        <header className="blog-header py-3">
          <BlogNavbar />
          
        </header>
      </div>

      <main className="container">
        <div className="p-4 p-md-5 mb-4 text-white rounded bg-dark" style={backgroundImageUrl ? divStyle : {}}>
          <div className="col-md-6 px-0 mainb">
            <h1 className="display-4 fst-italic ftitle">{featuredPost.title}</h1>
            <p className="lead my-3 fsubtitle">{featuredPost.subtitle}</p>
            <p className="lead mb-0">
              <a href="#" className="text-white fw-bold fcont" onClick={(e) => { e.preventDefault(); handlefullblog(featuredPost.id); }}>
                Continue reading...
              </a>
            </p>
          </div>
        </div>

        <div className="row mb-2">
          {blogPosts.map((post, index) => (
            <div className="col-md-6" key={index}>
              <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                <div className="col p-4 d-flex flex-column position-static">
              
                  <h3 className="mb-0 storytitle">{post.title}</h3>
                  <div className="mb-1 text-muted storytext_muted">Nov 12</div>
                  <p className="card-text mb-auto storysubtitle">{post.subtitle}</p>
                  <a href="#" className="stretched-link" onClick={(e) => { e.preventDefault(); handlefullblog(index); }}>
                    Continue reading
                  </a>
                </div>
                <div className="col-auto d-none d-lg-block">
                  <img src={post.imgSrc} alt='img' width={300} height={300} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
    </div>
  );
};

// Export the component
export default MainBlog;


