import React from 'react';
import { useNavigate } from 'react-router-dom';
import blogPosts from '../StoryApi/ArticleApi';
import './Article.css';

const Blog = () => {
  const navigate = useNavigate();

  const handleNavigate = (id) => {
    navigate(`/story/${id}`);
  };

  return (
    <div className="container">
      <div className="m-3">
        <h6 className="mission_header mt-3 mb-5">Recent Updates</h6>
        <div id="blogGrid" className="d-none d-md-grid blog-grid">
          {blogPosts.map((post, index) => (
            <div key={index} className="blog-card" onClick={() => handleNavigate(index)}>
              <img src={post.imgSrc} className="blog-img" alt={post.title} />
              <div className="blog-content">
                <p className="ptitle desktop-title">{post.title}</p>
                <p className="card-text text-muted desktop-subtitle">{post.subtitle}</p>
                <button className="btn btn-primary">Read More</button>
              </div>
            </div>
          ))}
        </div>
        <div id="blogCarousel" className="carousel slide d-md-none" data-bs-ride="carousel">
          <div className="carousel-inner">
            {blogPosts.map((post, index) => (
              <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <div className="mobile-blog-card">
                  <img src={post.imgSrc} className="mobile-blog-img" alt={post.title} />
                  <div className="mobile-blog-content">
                    <p className="ptitle mobile-title">{post.title}</p>
                    <p className="card-text text-muted mobile-subtitle">{post.subtitle}</p>
                    <button className="btn btn-primary" onClick={() => handleNavigate(index)}>Read More</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#blogCarousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#blogCarousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Blog;
