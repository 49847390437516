import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './fullstory.css';
import BlogNavbar from '../../component/Navbar/blognav';
import blogPosts from '../../component/StoryApi/ArticleApi';
import './fullstory.css';

const FullStory = () => {
  const { id } = useParams(); // Access the dynamic route parameter (id)
  const post = blogPosts[parseInt(id)]; // Convert id to integer and get the specific post
  const navigate = useNavigate();

  const divStyle = {
    backgroundImage: `url(${post.imgSrc})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const handleFullBlog = (id) => {
    navigate(`/story/${id}`); // Navigate to the full post by id
  };

  return (
    <div className="container">
      <BlogNavbar className="mb-5" />
      <div id="main-content" className="blog-page">
        <div className="container">
          <div className="row clearfix">
            <div className="col-lg-8 col-md-12 left-box">
              <div className="card single_post">
                <div className="body">
                  <div className="img-post">
                    <div
                      className="p-4 p-md-5 mb-4 text-white rounded bg-dark"
                      style={divStyle}
                    >
                      <div className="col-md-6 px-0 mainb">
                        <h1 className="display-4 fst-itali Abt1">{post.title}</h1>
                        <p className="lead my-3 p-5 Abt2">{post.subtitle}</p>
                      </div>
                    </div>
                  </div>
                  <h2 className='fullheader Abt1'>{post.title}</h2>
                  <p>
                    <div className="songwriter Abt2">{post.subtitle}</div>
                  </p>
                  {post.description.map((paragraph, index) => (
                    <p key={index} className='parafull'>{paragraph}</p>
                  ))}
                </div>
              </div>

              <div className="card">
                <div className="header">
                  <h2>Comments</h2>
                </div>
                <div className="body">
                  <ul className="comment-reply list-unstyled">
                    <li className="row clearfix">
                      <div className="icon-box col-md-2 col-4">
                        <img
                          className="img-fluid img-thumbnail"
                          src="https://bootdey.com/img/Content/avatar/avatar7.png"
                          alt="User Avatar"
                        />
                      </div>
                      <div className="text-box col-md-10 col-8 p-l-0 p-r0">
                        <p className='coment'>
                         This is educative
                        </p>
                        <h5 className="m-b-0 comentp">Gigi Hadid</h5>
                        <ul className="list-inline">
                          <li>
                            <a href="javascript:void(0);" className='reply'>Mar 09 2018</a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" className='reply'>Reply</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {/* Repeat for other comments */}
                  </ul>
                </div>
              </div>

             </div>

            <div className="col-lg-4 col-md-12 right-box">
              <div className="card">
                <div className="header">
                  <h2>Popular Posts</h2>
                </div>
                <div className="body widget popular-post">
                  <div className="row">
                    {blogPosts.slice(0, 2).map((Post, index) => (
                      <div key={index} className="m-2">
                        <div className="single_post">
                          <div className="img-post">
                            <img
                              src={Post.imgSrc}
                              alt="Awesome Image"
                              style={{ width: '100%' }}
                            />
                          </div>
                          <p className="readp">{Post.title}</p>
                          <p className="lead">
                            <a
                              href="#"
                              className="text-black fw-bold read mt-3"
                              onClick={(e) => {
                                e.preventDefault();
                                handleFullBlog(index); // Pass the correct index
                              }}
                             
                            >
                              Continue reading...
                            </a>
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullStory;
