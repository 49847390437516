import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './hero.css';

const Hero = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Define content for each route
  const content = {
    '/': {
      title: "Mental health is not a destination, it’s a journey.",
      buttons: [
        { text: 'Our Stories', path: '/story' },
        { text: 'About Us', path: '/AboutUs' },
      ],
    },
    '/story': {
      title: "Explore inspiring mental health journeys from real people.",
      buttons: [
        { text: 'Back to Home', path: '/' },
        { text: 'About Us', path: '/AboutUs' },
      ],
    },
    '/AboutUs': {
      title: "Learn more about our mission and values.",
      buttons: [
        { text: 'Home', path: '/' },
        { text: 'Blog', path: '/story' },
      ],
    },
    '/contact': {
      title: "We'd love to hear from you. Get in touch today!",
      buttons: [
        { text: 'Home', path: '/' },
        { text: 'About Us', path: '/AboutUs' },
      ],
    },
  };

  // Get the current content based on the route
  const currentContent = content[location.pathname] || content['/'];

  return (
    <div className="hero">
      <div className="hero_color">
        <div className="ment pt-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-lg">
          <div className="col-8 p-lg-5 pt-lg-3 mt-5 heroe_para">
            <div className="fw-bold Hero_p">{currentContent.title}</div>
            <div className="d-grid d-md-flex butt mt-3">
              {currentContent.buttons.map((button, index) => (
                <button
                  key={index}
                  className={`but${index + 1} shadow-lg rounded`}
                  onClick={() => navigate(button.path)} // Navigate on button click
                >
                  {button.text}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
