import React from "react";
import './blognav.css'
import { Link } from "react-router-dom";
import Logo from '../../IMG/logo.jpg'

const BlogNavbar = () => {
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark bg-transparent">
        <div className="container-fluid">
          <img src={Logo} alt="logo" style={{
              width: '100px',
              paddingBottom: '0px'
          }} />
          
          {/* Toggler for mobile */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar Links */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link to='/' className="nav-link">
                  Home
                </Link>
              </li>
              <li className="nav-item">
              <Link to='/story' className="nav-link">
                  blogPosts
                </Link>
              </li>
              <li className="nav-item">
              <Link to='/story' className="nav-link">
                  Partners
                </Link>
              </li>
              <li className="nav-item">
              <Link to='/story' className="nav-link">
                  Contact Us
                </Link>
              </li>
              
            </ul>
          </div>
        </div>
      </nav>
      
      {/* Optional: If you want to include an hr element */}
      <hr />
    </header>
  );
};

export default BlogNavbar;
